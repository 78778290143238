#userForm {
    flex-direction: row;
    justify-content:space-around;
    width: 52.5vw;
    padding-left: 0;
    padding-right: 0;
}

#userForm #loadImage input {
    width: 95%;
}

#userForm, #loadImage {
    align-self: baseline;
    height: 100%;
}

#userInformation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    width: 50%;
}

#userForm #loadImage .main_img {
    align-self: center;
    margin-bottom: 1vw;
    object-fit: cover;
    border-radius: 10px;
    height: 70%;
}

#loadImage {
    height: 28vw;
}

#userForm label {
    font-size: 1.05vw;
}

#accountInputs div{
    margin-bottom: 2vw;
    display: flex;
    flex-direction: column;
}

#accountButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 4vw;
    flex-wrap: wrap;
}

#accountButtons button {
    margin-bottom: 1.5vw;
    width: 12.7vw;
}

#progress {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: 1.5vw;
}

#userForm button:disabled {
    pointer-events: none;
    opacity: 0.5;
}

#footer-account {
    margin-top: 4.1vw;
}

@media screen and (orientation: portrait) {
    #progress {
        font-size: 4.5vw;
        width: 100%;
    }

    #accountButtons {
        flex-direction: column;
        width: 100%;
    }

    #userForm {
        flex-direction: column;
        width: 100%;
        margin-top: 3vw;
    }

    #userForm #loadImage {
        align-self: center;
        width: 70%;
        height: 45vw;
    }

    #userForm #loadImage input {
        margin-top: 1vw;
        font-size: 4vw;
        width: 95%;
    }

    #userForm label {
        font-size: 4vw;
    }

    #userInformation {
        width: 90%;
    }

    #accountButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    
    #accountButtons button {
        margin-bottom: 1vw;
        width: 40.5vw;
        font-size: 4.3vw;
    }

    #footer-account {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
    }
}