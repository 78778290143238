#test {
    font-weight: bold;
    color: #5F2300;
    font-size: 2.1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 1.5vw;
}

#answers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 1vw;
}

#question {
    margin: 0.5vw 0;
}

#answers button {
    font-weight: bold;
    font-size: 2vw;
    color: #8C4E2A;
    background-color: antiquewhite;
    border: 0.5vw solid #8C4E2A;
    border-radius: 5px;
    width: 24vw;
    margin: 1vw 5vw;
    transition: 0.3s;
}

#answers button:hover {
    cursor: pointer;
    transform: translateY(-0.3vw);
    color: #8C4E2A;
    box-shadow: 0.5vw 0.5vw 2px 1px #d6b29d;
}

#testImage {
    margin-top: 1vw;
    height: 19vw;
    border-radius: 5px;
    border: 0.5vw solid #8C4E2A;
}

#sadCapy {
    height: 52vh;
}

#answers button.correct {
    background-color: #5DB065; /* Зеленый цвет для правильного ответа */
  }
  
#answers button.incorrect {
    background-color: #FF6347; /* Красный цвет для неправильного ответа */
}

@media screen and (orientation: portrait) {
    #test {
        margin-top: 7vw;
        font-size: 7vw;
    }

    #answers {
        margin-top: 3vw;
        display: flex;
        flex-direction: column;
    }

    #answers button {
        font-size: 6vw;
        border: 0.7vw solid #8C4E2A;
        width: 100%;
        margin: 3vw 0;
    }

    #answers button:hover {
        transform: translateY(-1vw);
        box-shadow: 1.5vw 1.5vw 2px 1px #d6b29d;
    }

    #testImage {
        margin-top: 5vw;
        height: 55vw;
        border-radius: 5px;
        border: 1vw solid #8C4E2A;
    }

    #completeImage {
        margin-top: 3vw;
        height: 55vw;
    }

    #sadCapy {
        margin-top: 5vw;
        height: 90vw;
    }
}