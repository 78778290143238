.lessons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    margin: 1vw 0 3vw 0;
    width: 100%;
}

.lesson {
    width: 14.5vw;
    background-color: #FFDEB2;
    border-radius: 5px;
    border: 0.3vw solid #5F2300;
    margin: 1% 1.1%;
    padding: 2%;
    text-align: center;
    font-size: 1.05vw;
    font-weight: bold;
    color: #5F2300;
    cursor: pointer;
    transition: 0.5s;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.disabled {
    background-color: #ffdeb287;
    border: 0.3vw solid #5f23008c;
    color: #5f23006a;
    pointer-events: none;
}

.lesson:hover, #addLesson:hover, #addChord:hover {
    transform: translateY(-0.5vw);
}

#complete {
    margin-top: 0.3vw;
    margin-bottom: -1.8vw;
    margin-left: -15.5%;
    width: 131%;
    color: white;
    background-color: #58C189;
    border-radius: 5px;
    border: 0.5vmin solid #5F2300;
}

#lessonIcons { 
    margin-top: 5%;
    margin-bottom: -1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.lesson a {
    color: #5F2300;
    width: 133%;
}

.disabled a {
    color: #5f23006a;
}

#lessonIcons button, #lessonIcons a {
    width: 48%;
    border-radius: 3px;
    border: 0.2vw solid #5F2300;
    color:#5F2300;
    font-weight: bolder;
    cursor: pointer;
    font-size: 85%;
}

#lessonIcons a {
    background-color:rgb(241, 187, 121);
}

#lessonIcons button {
    background-color: rgb(241, 104, 104);
}

#addLesson {
    font-size: 3vw;
    margin-right: -4vw;
    margin-left: 1vw;
    background-color: #58C189;
    border: 0.3vw solid #5F2300;
    border-radius: 5px;
    padding: 0.5vw 0.5vw 0.3vw 0.5vw;
    cursor: pointer;
    transition: 0.5s;
    color: beige;
    height: 3.3vw;
    display: flex;
    align-items: center;
    font-family:Arial, Helvetica, sans-serif;
    align-self: center;
}

.lessonSearch {
    margin-left: 10%;
    width: 80%;
    margin-top: 2vw;
    font-size: 1.2vw;
    padding: 0.5vw 1vw;
    border-radius: 5px;
    border: 0.2vw solid #5F2300;
    background-color: #fff2e1;
    font-weight: bolder;
    color: #5F2300;
}

#signupLessons {
    position: absolute;
    z-index: 10;
    width: 100%;
    margin-top: 44vh;
    align-items: center;
    display: flex;
    left: 0;
    top: 0;
    justify-content: center;
}

#signupLessons button {
    border: 0.5vw solid #5F2300;
    font-size: 1.5vw;
}

.search {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 74%;
    margin-left: 13%;
}

@media screen and (orientation: portrait) {
    .lessons {
        margin: 7vw 0 12vw 0;
        flex-direction: column;
        align-items: center;
    }

    .lesson {
        width: 80vmin;
        font-size: 5.5vw;
        border: 1vmin solid #5F2300;
        padding: 4vw;
    }

    .lesson:hover, #addLesson:hover {
        transform: translateY(-1.7vw);
    }

    #complete {
        margin-bottom: -4.9vw;
        width: calc(100% + 9.5vw);
        margin-left: -4.75vw;
        border: 0.8vmin solid #5F2300;
    }

    #lessonIcons { 
        width: 100%;
        justify-content: space-evenly;
        margin-top: 3vw;
    }
    
    #lessonIcons button, #lessonIcons a {
        width: 32vw;
        border: 0.4vw solid #5F2300;
        font-size: 5vw;
    }
    
    #addLesson {
        font-size: 10vw;
        margin-left: 0vw;
        margin-top: 3vw;
        border: 0.5vw solid #5F2300;
        padding: 0vw 3vw;
        height: auto;
    }

    .lessonSearch {
        width: 95%;
        margin-left: 2.5%;
        align-self: baseline;
        margin-top: 5vw;
        font-size: 5vw;
    }

    #signupLessons {
        width: 95%;
        left: 2.5%;
        margin-top: 44vh;
        position: fixed;
    }
    
    #signupLessons button {
        border: 1vw solid #5F2300;
        font-size: 5.5vw;
        display: flex;
    }

    .search {
        width: 100%;
        margin-left: 0;
    }
}