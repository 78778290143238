#lessonPage input, textarea {
    border: 1px solid #5F2300;
    border-radius: 5px;
    background-color: antiquewhite;
    font-weight: bolder;
    color: #5F2300;
    font-size: 1.4vw;
}

#lessonTitle {
    display: flex;
    flex-direction: column;
    text-align: center;
}

#lessonTitle input {
    font-size: 2vw;
    text-align: center;
}

.lessonSection textarea, .lessonParagraph textarea, .lessonSection {
    width: 100%;
}

textarea {
    padding: 0.5vw;
    resize: vertical;
}

#otherSettings {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #5F2300;
    text-align: center;
    border-top: 0.2vw solid #5F2300;
    padding-top: 0.5vw;
}

#otherSettings div {
    margin-top: 1vw;
}

#subtitle {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: -3vw;
    margin-bottom: 2vw;
    color: #5F2300;
}

#subtitle input {
    text-align: center;
}

#practiceNotes {
    display: flex;
    flex-direction: column;
}

#practiceNotes button {
    cursor: pointer;
    border: 1px solid #5F2300;
    border-radius: 5px;
    background-color: rgb(247, 236, 226);
    font-weight: bolder;
    color: #5F2300;
    font-size: 1.4vw;
    margin: 0.2vw;
    transition: 0.3s;
}

#practiceNotes button:hover {
    background-color: #5F2300;
    color: rgb(247, 236, 226);
}

#practiceNotes textarea {
    margin-bottom: 0.5vw;
}

#checkboxes {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#otherSettingsThree {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#otherSettingsThree #loadImage {
    margin-bottom: 1vw;
    height: auto;
}

#repeat input {
    text-align: center;
}

#loadImage {
    display: flex;
    flex-direction: column;
    width: 35%;
    align-items: center;
}

#loadImage .main_img {
    width: 100%;
}

#loadImage input {
    width: 70%;
    margin-top: 0.7vw;
    border: none;
}

::file-selector-button {
    width: 100%;
    background-color: #5F2300;
    color: #5F2300;
    border-radius: 5px;
    padding: 0.3vw;
    cursor: pointer;
    border: none;
}

#loadImage span {
    margin-top: -2.2vw;
    color: antiquewhite;
    pointer-events: none;
    font-size: 1.3vw;
}

#isTest {
    display: flex;
    width: 50%;
    align-self: center;
    justify-content: space-evenly;
    align-items: center;
}

#isTest input[type="radio"] {
    position: absolute;
    opacity: 0;
}
  
#isTest label {
    cursor: pointer;
    box-sizing: border-box;
    padding: 0.7vw 1.5vw;
    border: 0.2vw solid antiquewhite;
}
  
#isTest label.active {
    border: 0.2vw solid #5F2300;
    border-radius: 5px;
}

#testEditor {
    margin-top: 2vw;
    color:antiquewhite;
    background-color: #5F2300;
    border-radius: 5px;
    width:fit-content;
    padding: 0.7vw 1.5vw;
    align-self: center;
    transition: 0.5s;
}

#testEditor:hover {
    transform: translateY(-0.5vw);
}

#langButton {
    font-size: 1.05vw;
}

@media screen and (orientation: portrait) {
    #lessonPage input, textarea {
        font-size: 4.5vw;
    }

    #lessonTitle input {
        font-size: 6.5vw;
    }

    .lessonSection textarea, .lessonParagraph textarea, .lessonSection {
        flex-direction: column;
    }

    .lessonParagraph {
        width: 95%;
    }

    #otherSettings {
        margin-top: 5vw;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    #otherSettings div {
        margin-top: 2vw;
    }

    .№5 {
        flex-direction: column-reverse;
    }

    #loadImage {
        width: 100%;
        margin-bottom: 5vw;
        height: auto;
    }
    
    #loadImage input {
        width: 75%;
        margin-top: -2vw;
    }
    
    ::file-selector-button {
        width: 100%;
        padding: 1.5vw;
    }
    
    #loadImage span {
        color: antiquewhite;
        pointer-events: none;
        font-size: 5vw;
        margin-top: -8vw;
    }

    textarea {
        height: 35vw;
    }
    
    #practiceNotes button {
        font-size: 5vw;
        margin: 0.6vw;
    }
    
    #practiceNotes textarea {
        margin-bottom: 1vw;
    }

    #otherSettingsThree {
        flex-direction: column;
    }

    #isTest {
        width: 90%;
    }
    
    #testEditor {
        margin-top: 4vw;
        padding: 2vw 3.5vw;
        align-self: center;
        transition: 0.5s;
    }

    #testEditor:hover {
        transform: translateY(-1vw);
    }

    #langButton {
        font-size: 4vw;
    }
}
