#testConst {
    font-weight: bolder;
    display: flex;
    flex-direction: column;
}

#questionConst {
    display: flex;
    flex-direction: column;
    color: #8A3100;
    font-size: 1.5vw;
    align-items: center;
    width: 100%;
    margin-bottom: 2vw;
}

#questionConst input {
    color: #8A3100;
    border: 0.2vw solid #8A3100;
    border-radius: 5px;
    font-size: 1.5vw;
    background-color: antiquewhite;
    font-weight: bolder;
    text-align: center;
}

#questionConstTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin-top: 1vw;
}

#questionConstTitle input {
    width: 82%;
}

#optionsConst {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 80%;
    margin-top: 1vw;
}

#optionsConst input{
    margin: 1vw;
}

#correctAnswerConst {
    margin-top: 2vw;
}

#testConstImage input {
    border: none;
    width: 55%;
    margin-top: 0.5vw;
}

#testConstImage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#testConstImage span {
    margin-top: -2.4vw;
    color: antiquewhite;
    pointer-events: none;
}

#deleteQuizze {
    width: 10%;
    margin-top: 0;
    margin-left: 2vw;
}

@media screen and (orientation: portrait) {

#testConst {
    font-size: 4vw;
}

#questionConst {
    font-size: 4vw;
    width: 100%;
    margin-bottom: 4vw;
}

#questionConst input {
    border: 0.5vw solid #8A3100;
    font-size: 4vw;
}

#questionConstTitle {
    margin-top: 2vw;
}

#questionConstTitle input {
    width: 65%;
}

#optionsConst {
    margin-top: 2vw;
}

#optionsConst input{
    margin: 1.5vw;
}

#testConstImage input {
    width: 64%;
    margin-top: 1.5vw;
    border: none;
}

#testConstImage span {
    margin-top: -7vw;
}

#deleteQuizze {
    width: 25%;
    font-size: 3.5vw;
}

#correctAnswerConst {
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 3vw;
}
}