#lessonPage {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: bold;
    color: #8C4E2A;
    font-size: 1.4vw;
}

#lessonTitle {
    margin: 3vw 0vw 4vw 0vw;
    font-size: 2vw;
    color: #5F2300;
}

.lessonSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3vw;
    align-items: center;
}

.lessonParagraph {
    margin-top: 0vw;
    width: 55%;
}

#lessonPage .main_img {
    width: 35%;
}

.notFound {
    display: flex;
    flex-direction: column;
    font-size: 4vw;
    color: #5F2300;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.notFound img {
    width: 20vw;
}

#comments-container {
    display: flex;
    flex-direction: column;
    color: #5F2300;
    font-weight: bolder;
    font-size: 1.15vw;
}

#comment {
    display: flex;
    margin: 1vw 0vw;
}

#reply {
    display: flex;
    padding-left: 2vw;
}

#comment_info {
    margin-left: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#comment_text {
    color: #8C4E2A;
    width: auto;
    word-wrap: break-word;
}

#comment_info div:last-child {
    font-weight: 100;
}

#comment_image {
    object-fit: cover;
    width: 6vw;
    height: 6vw;
    border: 0.2vw solid #5F2300;
    border-radius: 5px;
    margin-top: 0.4vw;
}

#comment_container {
    display: flex;
    flex-direction: column;
}

#comment_last {
    display: flex;
    flex-direction: row;
    width: 64.7vw;
    justify-content: space-between;
}

#comment_last_reply {
    display: flex;
    flex-direction: row;
    width: 62.7vw;
    justify-content: space-between;
}

#comment_delete {   
    display: flex;
    flex-direction: row;
    align-items: end;
}

#comment_delete button {
    cursor: pointer;
    text-decoration: underline;
    font-size: 1vw;
    background:none;
    border: none;
    color: #5F2300;
}

#comments-container textarea {
    margin-top: 1vw;
    font-size: 1.3vw;
    border: 0.15vw solid #8C4E2A;
    color: #8C4E2A;
}

#comments-container button {
    width: fit-content;
    font-size: 1vw;
}

#replyingTo {
    margin-top: 1vw;
    margin-bottom: -1vw;
}

#replyingTo button{
    background: none;
    border: none;
    color: #5F2300;
    cursor: pointer;
}

#addComment {
    display: flex;
    flex-direction: column;
}

.main-button:disabled {
    pointer-events: none;
    opacity: 0.7;
}

#lessonPageButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
}

#lessonPageButtons button, #sort-comments {
    background: none;
    border: none;
    font-size: 1.1vw;
    text-decoration: underline;
    color: #5F2300;
    cursor: pointer;
}

#replies_button {
    background: none;
    border: none;
    cursor: pointer;
    color: #5F2300;
}

@media screen and (orientation: portrait) {
    #lessonPage {
        font-size: 4.5vw;
    }

    #lessonTitle {
        margin: 5vw 0vw;
        font-size: 6.5vw;
    }

    .lessonSection {
        flex-direction: column;
        margin-bottom: 0vw;
    }

    .lessonParagraph {
        width: 95%;
    }

    #lessonPage .main_img {
        margin: 7vw 0vw;
        width: 85%;;
    }

    .notFound {
        font-size: 9vw;
    }

    .notFound img {
        width: 50vw;
    }

    #comments-container {
        font-size: 5vw;
    }
    
    #comment {
        margin: 2vw 0vw;
    }
    
    #comment_info {
        margin-left: 3vw;
    }
    
    #comment_image {
        object-fit: cover;
        width: auto;
        height: 25vw;
        border: 0.7vw solid #5F2300;
        margin-top: 1.8vw;
    }
    
    #comment_last {
        flex-direction: column;
        font-size: 4vw;
    }

    #comment_last_reply {
        font-size: 4vw;
    }

    #replyingTo {
        margin-top: 1vw;
        margin-bottom: 1vw;
    }
    
    #comment_delete {   
        font-size: 5vw;
        justify-content: center;
        width: 100%;
    }
    
    #comments-container textarea {
        margin-top: 3vw;
        font-size: 5vw;
        border: 0.3vw solid #8C4E2A;
        padding: 1vw 2vw;
    }
    
    #comments-container button {
        width: fit-content;
        font-size: 4vw;
        margin-top: 3vw;
    }

    #lessonPageButtons {
        justify-content: center;
        flex-direction: column-reverse;
        margin-top: 3vw;
    }

    #lessonPageButtons button, #sort-comments {
        font-size: 5vw;
        margin-top: 1vw;
    }
}

@media print {
    #lessonPage {
        margin-top: 5vw;
    }

    .main-button, .print-button {
        display: none;
    }

    #comments-container {
        display: none;
    }
}