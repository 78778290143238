html, body {
  margin: 0vw 0vw;
  padding: 0;
  overflow-x: hidden;
  background-color: antiquewhite;
}

body {
  margin: 0vw 13vw;
}

::-webkit-scrollbar {
  width: 1.5vmin;
}

::-webkit-scrollbar-track {
  background: none;
  margin-right: 1vmin;
}

::-webkit-scrollbar-thumb {
  background-color: #5F2300;
  border-radius: 5px;
  cursor: auto;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5f2300d4;
}

img {
  user-select: none;
}

#preloader {
  font-family: 'Dhyana', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4vw;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FAEBD7;
  color: #5F2300;
  font-weight: bold;
  z-index: 50;
}

#loader {
  width: 4vw;
  height: 4vw;
  border: 0.6vw solid #5F2300;
  border-top-color: #c0a989;
  margin-left: 2vw;
  border-radius: 50%;
  animation: spin 1s infinite ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (orientation: portrait) {
  body {
    margin: 0 3vw;
  }

  #preloader {
    font-size: 8vw;
  }
  
  #loader {
    width: 9vw;
    height: 9vw;
    border: 1.3vw solid #5F2300;
    border-top-color: #c0a989;
    margin-left: 4vw;
  }
}