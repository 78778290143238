#resetPassword form input {
    width: 100%;;
}

#resetPassword form {
    margin-top: 2vw;
    width: 40vw;
}

#resetPassword div {
    width: 100%;
    margin: 4vw 0;
}

#resetPassword h2 {
    font-size: 2vw;
}

#resetPassword button {
    margin-top: 3vw;
}

#resetPassword p {
    font-size: 1.2vw;
}

@media screen and (orientation: portrait) {
    #resetPassword form input{
        margin: 6vw 0;
    }
    
    #resetPassword form {
        margin-top: 10vw;
        width: 90vw;
    }
    
    #resetPassword h2 {
        font-size: 7vw;
    }
    
    #resetPassword button {
        margin-top: 5vw;
    }
    
    #resetPassword p {
        font-size: 5vw;
    }
}